<template>
    <div class="quemSomos">
        <div class="container-fluid px-0">
            <div class="row align-items-center justify-content-center">
                <div class="col-12 stripe text-center" title="Autor: Afonso Meneses; Nome comum: Aranha-caranguejo">
                    <span class="tit1">Sobre o</span><br />
                    <span class="tit2">SALVE</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row text-left pt-5" >
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        O Sistema de Avaliação do Risco de Extinção da Biodiversidade - SALVE foi desenvolvido com o objetivo de facilitar a gestão do processo de avaliação do risco de extinção coordenado e executado pelo ICMBio; o sistema possibilita também a organização das informações sobre a fauna avaliada. 
                    </p>
                    <p class="text-justify">
                        Por meio do SALVE, consolidamos uma base de dados para o armazenamento e organização das informações sobre as espécies e como uma ferramenta de controle, acompanhamento e execução das diferentes etapas do processo, desde o início da compilação de dados sobre as espécies, passando pela sistematização das informações sobre os pesquisadores e instituições parceiras envolvidas, até a avaliação do risco de extinção das espécies e publicação dos resultados. 
                    </p>
                    <p class="text-justify">
                        O desenvolvimento do SALVE teve início em 2016 pelo desenvolvedor sênior Luís Eugênio Barbosa, contratado à época por meio de uma bolsa CNPq no âmbito do “Projeto Gestão da Informação sobre biodiversidade: transformando dados em ações para a conservação”. A discussão das funcionalidades necessárias contou com a participação de servidores e bolsistas vinculados aos Centros Nacionais de Pesquisa e Conservação e da sede do ICMBio, todos com atuação no Processo de Avaliação do Risco de Extinção da Fauna. 
                    </p>
                </div>
                <div class="col-md-6 col-12">
                    <p class="text-justify">
                        A partir de 2020, o desenvolvimento do sistema passou a ter apoio do Projeto GEF Pró-Espécies, executado com recursos do Fundo Global para o Meio Ambiente (GEF, na sigla em inglês), também por meio de disponibilização de bolsa de desenvolvimento tecnológico, bem como pela contratação de empresa que desenvolveu o design da interface pública do sistema. O Projeto conta com o WWF como agência executora e com o FUNBIO como agência implementadora. Alguns dos principais objetivos do apoio ao SALVE no âmbito deste Projeto é a disponibilização dos dados das espécies avaliadas à sociedade e a integração dos dados da fauna mantidos no SALVE com os da flora mantidos pelo Centro de Conservação da Flora do Jardim Botânico do Rio de Janeiro (CNCFlora/JBRJ). O desenvolvimento do SALVE também recebe também apoio do Projeto “Estratégias para Conservação da Fauna Ameaçada de Extinção”, coordenado pela Coordenação Geral de Estratégias para Conservação do ICMBio.
                    </p>
                    <p class="text-justify">
                        O SALVE é operado por servidores do ICMBio. Os parceiros, especialistas da comunidade científica, atuam incluindo e atualizando dados sobre as espécies da fauna no banco de dados e auxiliando o processo por meio da organização, revisão e correção dessas informações, além da realização em si da avaliação do risco de extinção das espécies nas oficinas e posterior validação dos resultados.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import api from '@/services/api.js'
export default {
    data() {
        return {
            items: [],
            activeBtn:'btn1'
        }
    },
    created() {
        var t = this
        api.get('/redBooks').then(response => (
            t.items = response.data.data
        ))
    },
    methods: {
    }
}
</script>

<style scoped>
    .text-justify{
        text-align: justify;
    }
    .stripe{
        background-image: url(/img/animals/aranha-full.png);
        background-size: cover;
        height: 350px;
        padding-top: 10rem;
    }
    .ball-list {
        margin-top: -50px;
    }
    .row-text {
        margin-right: 12%;
        margin-left: 12%;
    }
    .table-button {
        background-color: transparent;
        margin-left: 25px;
        margin-right: 25px;
        border: none;
        border-bottom: 7px solid #ffffff;
    }
    .active {
        font-weight: bold;
        border-bottom: 7px solid #E2F5A1;
    }
    .separator {
        margin-bottom: 30px;
        border-bottom: 3px solid #E2F5A1;
        margin-left: 12vw;
        margin-right: 12vw;
    }
        .tit1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #282828;
        border-top: 3px solid black;
        padding-top: 1.5rem;
    }
    .tit2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #282828;
    }
@media screen and (max-width: 600px) {
    .tit1 {
    font-size: 1.3rem;
    padding-top: .5rem;
    }
    .tit2 {
        font-size: 1.3rem;
    }
    .stripe{
        height: 115px;
        padding-top: 40px;
    }
}
</style>